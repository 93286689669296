import { defineStore } from 'pinia';
import { ofetch } from 'ofetch';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')) || {},
    loggedIn: localStorage.getItem('loggedIn') === 'true',
    walletTab: false,
    inPlay: false,
    showVipIntroModal: false,
    newLevel: false,
    receivedRolls: false,
    receivedReward: false,
  }),
  actions: {
    toggleWalletTab(value) {
      this.walletTab = value;
    },
    updateInPlay(value) {
      this.inPlay = value;
    },
    toggleShowVipIntroModal(value) {
      this.showVipIntroModal = value;
    },
    updateUser(updates) {
      Object.assign(this.user, updates);
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    levelUp(value) {
      this.newLevel = value;
    },
    toggleReceivedRolls(value) {
      this.receivedRolls = value;
    },
    toggleReceivedReward(value) {
      this.receivedReward = value;
    },
    isDataValid() {
      try {
        const ttl = 300000; // Time to live in milliseconds, 5 mins
        const loggedAt = JSON.parse(localStorage.getItem('loggedAt'));
        if (!loggedAt) {
          return false;
        }
        const now = new Date().getTime();
        const ttlIsValid = now - loggedAt < ttl;

        if (ttlIsValid && !this.user.confirmed) return false; // If not confirmed, re-fetch to check it

        return ttlIsValid;
      } catch (e) {
        return false;
      }
    },

    saveUserData() {
      localStorage.setItem('user', JSON.stringify(this.user));
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('loggedAt', new Date().getTime());
    },

    clearUserData() {
      localStorage.setItem('user', JSON.stringify({}));
      localStorage.setItem('loggedIn', 'false');
      localStorage.removeItem('loggedAt');
    },

    async fetchUser(login = false) {
      if (!login && !this.loggedIn) {
        return;
      }
      if (this.loggedIn && this.isDataValid()) {
        return this.user;
      }
      try {
        const user = await this.apiFetch('/api/user');
        if (user) {
          this.loggedIn = true;
          this.user = user;
          this.saveUserData(user);
        } else {
          this.clearUserData();
        }
      } catch (error) {
        this.clearUserData();
        console.log(error);
      }
    },
    async login(data) {
      await this.csrf();
      try {
        await this.apiFetch('/api/login', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'X-XSRF-TOKEN': useCookie('XSRF-TOKEN').value,
          },
        });

        await this.fetchUser(true);

        navigateTo('/free');
      } catch (error) {
        console.log(error);
        this.clearUserData();
        throw error.data;
      }
    },
    async register(data) {
      await this.csrf();
      try {
        await this.apiFetch('/api/register', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'X-XSRF-TOKEN': useCookie('XSRF-TOKEN').value,
          },
        });
        await this.fetchUser(true);
        navigateTo('/free');
      } catch (error) {
        console.log(error);
        this.clearUserData();
        throw error.data;
      }
    },
    async logout() {
      try {
        await this.apiFetch('/api/logout', {
          method: 'POST',
        });
      } catch (error) {
        this.deleteCookie('XSRF-TOKEN');
        console.log(error);
      } finally {
        this.loggedIn = false;
        this.user = null;
        this.clearUserData();
        navigateTo('/');
      }
    },
    apiFetch(endpoint, options) {
      const fetch = ofetch.create({
        baseURL: useNuxtApp().$apiUrl,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'X-XSRF-TOKEN': useCookie('XSRF-TOKEN').value,
        },
      });
      return fetch(endpoint, options);
    },

    deleteCookie(cookieName) {
      document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },

    async csrf() {
      this.deleteCookie('XSRF-TOKEN');
      await ofetch('/sanctum/csrf-cookie', {
        baseURL: useNuxtApp().$apiUrl,
        credentials: 'include',
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
    },
  },
});
